import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Card from '../components/Card'
import Link from '../components/Link'

import oneLessonImage from '../assets/img/services/dog-training-treat.jpg'
import threeLessonsImage from '../assets/img/services/dog-leash-walking.jpg'
import fiveLessonsImage from '../assets/img/services/fetch-can-trick.jpg'
import puppyImage from '../assets/img/services/puppy-training-in-chicago.jpg'

import './Services.scss'

const Services = ({ location }) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training | Services'
    description='Pawsome Dogs provides dog and puppy training in Chicago. Professional in home dog trainers, specializing in obedience and behavior training.'>
    <main className='Services'>

      <Container className='Services--container'>

        <h2>In Home Private Lessons</h2>
        <p>All Chicago dog training private lessons are done either in your home or
          neighborhood. Lessons are customized to work on what you want to focus
          on. Perfect for someone with a busy schedule, a new puppy, behavioral issue,
          or wants training with real world distractions. Each lesson is one hour.</p>

        <p className="Services--container--completed">
          To ensure the best training for your pup all lessons in a purchased package
          must be completed in 4 months from purchase date.
        </p>
        
        <div className='Services--container--packages'>

          <Card className='Services--container--card'>
            <h3>1 Lesson</h3>
            <div className='fc'>
              <p className='Services--container--card--text'>Perfect for
                someone who just needs help with one specific behavior. House
                breaking, loose leash walking, and mild separation anxiety are examples.</p>
              <img src={oneLessonImage} alt='positive reinforcement' />
            </div>
            <h4 className='Services--container--card--price'>$110</h4>
          </Card>

          <Card className='Services--container--card'>
            <h3>3 Lessons</h3>
            <div className='fc'>
              <p className='Services--container--card--text--packages--single--text'>Great for a new
                dog who needs help with the basics. Obedience work, mild leash reactivity,
                loose leash walking with distractions, and stay.</p>
              <img src={threeLessonsImage} alt='dog leash walking' />
            </div>
            <h4 className='Services--container--card--price'>$300</h4>
          </Card>

          <Card className='Services--container--card standout'>
            <div className='standout--text'><span>Best Seller</span></div>
            <h3>5 Lessons</h3>
            <div className='fc'>
              <p className='Services--container--card--text'>The 5 lesson
                package is the opportunity to take your dog's training to a
                higher level. We will work through increasing challenging scenarios to
                get the results you want.</p>
              <img src={fiveLessonsImage} alt='fetch drink trick' />
            </div>
            <div className='Services--container--card--price'>$475</div>
          </Card>

          <Card className='Services--container--card'>
            <h3>Puppy Primer</h3>
            <div className='fc'>
              <div className='Services--container--card--wrapper'>
                <p className='Services--container--card--text'>Getting a
                  new puppy? Then this package is for you.</p>
                <ul>
                  <li><span className='red'>&#9679;</span> House Training</li>
                  <li><span className='red'>&#9679;</span> Nipping and Chewing</li>
                  <li><span className='red'>&#9679;</span> Name Recognition</li>
                  <li><span className='red'>&#9679;</span> Basic Leash Walking</li>
                </ul>
                <p className='note'>For puppies 5 months and under. Package consists of 3 lessons.</p>
              </div>
              <img src={puppyImage} alt='puppy training in chicago' />
            </div>
            <div className='Services--container--card--price'>$285</div>
          </Card>

        </div>

        <p className='Services--container--10'>We also offer a package of 10 lessons. <Link to='/Contact' red>Contact us</Link> for pricing.</p>

      </Container>

    </main>
  </Layout>

export default Services
