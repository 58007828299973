import React from 'react'
import PropTypes from 'prop-types'

import './Card.scss'

const Card = ({ children, className, ...others }) => {
  return (
    <article className={`Card ${className || ''}`} {...others}>
      {children}
    </article>
  )
}

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default Card
